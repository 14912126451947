import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles.hero.css';

import ESC_model from '../../../assets/img/hero-banner-3.svg';
import ESC_model_2 from '../../../assets/img/hero-banner-2.png';
import Tick_icon from '../../../assets/img/tick.png';
import Carousel from '../../Carousel/Carousel';

const heroData = [
    {
        title: 'The best solution for development',
        subTitle: 'The Future of IT services has Arrived',
        features: [
            'High speed, the best quality',
            'Meet 100% requirements of clients',
            'No Annual Upkeep or Maintenance',
            '100% Fully guarantee',
        ],
        content: 'NELSON TECH Limited liability company delivers cutting-edge technologies for a cleaner, sustainable world.',
        btn: 'Contact Us',
        img: ESC_model_2,
    },
    {
        title: 'Hire developers',
        subTitle: 'Trusted agency specializing in creating bespoke websites and optimizing online presence for businesses',
        features: [
            'Increased tech and power density',
            'Faster learning and improving',
            'Longer operational lifespan with enhanced ability',
            'Lightweight and environmentally friendly',
            'Flexible design for various applications',
            'Reduced internal resistance for better efficiency'
        ],
        content: 'Our company of skilled developers and designers is dedicated to crafting unique and effective solutions tailored to your company',
        btn: 'Contact Us',
        img: ESC_model,
    }
];

function Hero() {
    const sliderRef1 = useRef(null);
    const sliderRef2 = useRef(null);
    const [maxHeight, setMaxHeight] = useState(0);

    useEffect(() => {
        const height1 = sliderRef1.current.clientHeight;
        const height2 = sliderRef2.current.clientHeight;
        setMaxHeight(Math.max(height1, height2) + 200);
    }, []);

    const items = [
        <div className="hero" ref={sliderRef1} style={{ height: `${maxHeight}px` }}>
            <div className='hero-content' data-aos='fade-down'>
                <div className='hero-title'>{heroData[0].title}</div>
                <div className='hero-sub-title'>{heroData[0].subTitle}</div>
                <div className='hero-features'>
                    {heroData[0].features.map((item, index) => (
                        <div key={index} className='hero-feature-item'>
                            <img src={Tick_icon} alt='tick' />
                            <div>{item}</div>
                        </div>
                    ))}
                </div>
                <div className='hero-description'>{heroData[0].content}</div>
                <div className='hero-hidden-image'>
                    <img src={heroData[0].img} alt='hero-esc' className='hero-image' />
                </div>
                <div className='sub'>
                    <Link to='/contact'>
                        <div className='button-c'>{heroData[0].btn}</div>
                    </Link>
                </div>
            </div>
            <div className='hero-slide' data-aos='fade-down'>
                <img src={heroData[0].img} alt='hero-esc' className='hero-image' />
            </div>
        </div>,
        <div className="hero" ref={sliderRef2} style={{ height: `${maxHeight}px` }}>
            <div className='hero-content' data-aos='fade-down'>
                <div className='hero-title'>{heroData[1].title}</div>
                <div className='hero-sub-title'>{heroData[1].subTitle}</div>
                <div className='hero-features font-mid'>
                    {heroData[1].features.map((item, index) => (
                        <div key={index} className='hero-feature-item'>
                            <img src={Tick_icon} alt='tick' />
                            <div>{item}</div>
                        </div>
                    ))}
                </div>
                <div className='hero-description'>{heroData[1].content}</div>
                <div className='hero-hidden-image'>
                    <img src={heroData[1].img} alt='hero-esc' className='hero-image' />
                </div>
                <div className='sub'>
                    <Link to='/contact'>
                        <div className='button-c'>{heroData[1].btn}</div>
                    </Link>
                </div>
            </div>
            <div className='hero-slide' data-aos='fade-down'>
                <img src={heroData[1].img} alt='hero-esc' className='hero-image' />
            </div>
        </div>
    ];

    return (
        <Carousel items={items} />
    );
}

export default Hero;
