import PDF_rack from '../assets/ESC Rack Mount Standard Specifications.pdf'
import Table_rack from '../assets/img/specifications-rack.png'
import Img_rack from '../assets/img/rack.png'
import PDF_wall from '../assets/ESC Wall Mount Standard Specifications.pdf'
import Table_wall from '../assets/img/specifications-wall.png'
import Img_wall from '../assets/img/wall.png'
import Product_rack from '../assets/img/produc-t.png'
import Product_wall from '../assets/img/produc-b.png'

import Product_Soon_1 from '../assets/img/pro-soon-1.png'
import Product_Soon_2 from '../assets/img/pro-soon-2.png'
import Product_Soon_3 from '../assets/img/pro-soon-3.png'
import Product_Soon_4 from '../assets/img/pro-soon-4.png'
import Product_Soon_5 from '../assets/img/pro-soon-5.png'
import Product_Soon_6 from '../assets/img/pro-soon-6.png'

export const ESC_data = {
    rack: {
        id: 1, name: 'ESC Rack Mount', model: '24V', like: false, price: '$ 94.58', sku: 'ESC-RM-5.25/48',
        description: 'Each Hybrid Supercapacitor Battery module has a rated voltage range from 42V to 58.8V, with a capacity options ranging from 5250Wh to 21000Wh. It supports a standard charge and discharge current of 50A, with maximum pulse discharge up to 150A, and operates within temperatures from -20°C to 60°C. With a cycle life of over 50,000 cycles and compatibility with popular inverters like Victron and SMA, it`s designed for efficient, long-lasting performance in parallel systems. Custom voltages upon request.',
        characters: [
            '100% Depth of Discharge (DoD)',
            'Safe — No risk of thermal runaway',
            'Long Lifespan/Cycle Life',
            'Wide operating temperature',
            'Low ESR',
            'Green/Environmentally Friendly',
            'Low maintenance',
        ],
        capacities: ['5kWh (100Ah)', '10kWh (200Ah)', '20kWh (400Ah)'],
        img: Product_rack,
        specifications: {table: Table_rack, img: Img_rack, pdf: PDF_rack},
    },
    wall: {
        id: 1, name: 'ESC Wall Mount', model: '24V', like: false, price: '$ 131.00', sku: 'ESC-RM-5.25/48',
        description: 'Each Hybrid Supercapacitor Battery module has a rated voltage range from 42V to 58.8V, with a capacity options ranging from 5250Wh to 21000Wh. It supports a standard charge and discharge current of 50A, with maximum pulse discharge up to 150A, and operates within temperatures from -20°C to 60°C. With a cycle life of over 50,000 cycles and compatibility with popular inverters like Victron and SMA, it`s designed for efficient, long-lasting performance in parallel systems. Custom voltages upon request.',
        characters: [
            '100% Depth of Discharge (DoD)',
            'Safe — No risk of thermal runaway',
            'Long Lifespan/Cycle Life',
            'Wide operating temperature',
            'Low ESR',
            'Green/Environmentally Friendly',
            'Low maintenance',
        ],
        capacities: ['5kWh (100Ah)', '10kWh (200Ah)', '20kWh (400Ah)'],
        img: Product_wall,
        specifications: {table: Table_wall, img: Img_wall, pdf: PDF_wall},
    }
}

export const productsData = {
	rack: {
		description: 'Our  HSC Rack mount battery is available in 24V, 3V, and 48V with capacity options ranging from 5kWh-20kWh.  This is the ideal model for industrial use in data centers and backup power.   It supports a standard charge and discharge current of 50A, with maximum pulse discharge up to 150A, and operates within temperatures from -20°C to 60°C. With a cycle life of over 50,000 cycles and compatibility with popular power plants and inverters like Ericsson, Victron and SMA, it`s designed for efficient, long-lasting performance in parallel systems.',
		items: [
			{
				id: 1, name: 'ESC Rack Mount', model: '24V', like: false, price: '$ 94.58', sku: 'ESC-RM-5.25/48',
				description: 'Each Hybrid Supercapacitor Battery module has a rated voltage range from 42V to 58.8V, with a capacity options ranging from 5250Wh to 21000Wh. It supports a standard charge and discharge current of 50A, with maximum pulse discharge up to 150A, and operates within temperatures from -20°C to 60°C. With a cycle life of over 50,000 cycles and compatibility with popular inverters like Victron and SMA, it`s designed for efficient, long-lasting performance in parallel systems. Custom voltages upon request.',
				characters: [
					'100% Depth of Discharge (DoD)',
					'Safe — No risk of thermal runaway',
					'Long Lifespan/Cycle Life',
					'Wide operating temperature',
					'Low ESR',
					'Green/Environmentally Friendly',
					'Low maintenance',
				],
				capacities: ['5kWh (100Ah)', '10kWh (200Ah)', '20kWh (400Ah)'],
				img: Product_rack,
				specifications: { 
					table: [
						{ label: 'Rated Energy', value: 'WH (@50A at 25C) Discharge\nfrom 29.4V to 24V with 50A' },
						{ label: 'Max Charge Voltage', value: '29.4V.DC' },
						{ label: 'Voltage', value: '≥24V' },
						{ label: 'Min Discharge Voltage', value: '24V' },
						{ label: 'Charge Current', value: 'Standard Charge: 50A\nMax Charge: 100A' },
						{ label: 'Discharge Current', value: 'Standard Discharge: 50A\nMax Discharge: 100A\nPulse Current: 150A' },
						{ label: 'Operating Temperature', value: 'Charging: -10°C ~ 55°C\nDischarging: -20°C ~ 60°C' },
						{ label: 'Impedance', value: 'Impedance ≤8mΩ (AC Impedance, 1000 Hz 48V@25±3°C)' },
						{ label: 'Max Qty of Module in Parallel Connection', value: '≤15 Sets' },
						{ label: 'Cycle Life', value: '≥50000 Cycles with 50A at 20°C' },
						{ label: 'Storage Temperature / Humidity', value: '-20°C ~ + 60°C / ≤70%' },
						{ label: 'Support for Inverter Models', value: 'Victron, Pylon, SMA' },
						{ label: 'Safety Test', value: 'Insulation Resistance ≥50MΩ' },
						{ label: 'Dimensions', value: 'Length: ≤650mm\nWidth: ≤432mm\nHeight: ≤160mm' },
						{ label: 'Weight', value: 'About 112 Lbs.' },
					], 
					img: Img_rack, 
					pdf: PDF_rack },
			},
			{
				id: 2, name: 'ESC Rack Mount ', model: '36V', like: true, price: '$ 121.30', sku: 'ESC-RM-5.25/48',
				description: 'Each Hybrid Supercapacitor Battery module has a rated voltage range from 42V to 58.8V, with a capacity options ranging from 5250Wh to 21000Wh. It supports a standard charge and discharge current of 50A, with maximum pulse discharge up to 150A, and operates within temperatures from -20°C to 60°C. With a cycle life of over 50,000 cycles and compatibility with popular inverters like Victron and SMA, it`s designed for efficient, long-lasting performance in parallel systems. Custom voltages upon request.',
				characters: [
					'100% Depth of Discharge (DoD)',
					'Safe — No risk of thermal runaway',
					'Long Lifespan/Cycle Life',
					'Wide operating temperature',
					'Low ESR',
					'Green/Environmentally Friendly',
					'Low maintenance',
				],
				capacities: ['5kWh (100Ah)', '10kWh (200Ah)', '20kWh (400Ah)'],
				img: Product_rack,
				specifications: { 
					table: [
						{ label: 'Rated Energy', value: 'WH (@50A at 25C) Discharge\nfrom 44.1V to 36V with 50A' },
						{ label: 'Max Charge Voltage', value: '44.1.DC' },
						{ label: 'Voltage', value: '≥36V' },
						{ label: 'Min Discharge Voltage', value: '36V' },
						{ label: 'Charge Current', value: 'Standard Charge: 50A\nMax Charge: 100A' },
						{ label: 'Discharge Current', value: 'Standard Discharge: 50A\nMax Discharge: 100A\nPulse Current: 150A' },
						{ label: 'Operating Temperature', value: 'Charging: -10°C ~ 55°C\nDischarging: -20°C ~ 60°C' },
						{ label: 'Impedance', value: 'Impedance ≤8mΩ (AC Impedance, 1000 Hz 48V@25±3°C)' },
						{ label: 'Max Qty of Module in Parallel Connection', value: '≤15 Sets' },
						{ label: 'Cycle Life', value: '≥50000 Cycles with 50A at 20°C' },
						{ label: 'Storage Temperature / Humidity', value: '-20°C ~ + 60°C / ≤70%' },
						{ label: 'Support for Inverter Models', value: 'Victron, Pylon, SMA' },
						{ label: 'Safety Test', value: 'Insulation Resistance ≥50MΩ' },
						{ label: 'Dimensions', value: 'Length: ≤650mm\nWidth: ≤432mm\nHeight: ≤160mm' },
						{ label: 'Weight', value: 'About 112 Lbs.' },
					], 
					img: Img_rack, 
					pdf: PDF_rack },
			},
			{
				id: 3, name: 'ESC Rack Mount', model: '48V', like: false, price: '$ 131.00', sku: 'ESC-RM-5.25/48',
				description: 'Each Hybrid Supercapacitor Battery module has a rated voltage range from 42V to 58.8V, with a capacity options ranging from 5250Wh to 21000Wh. It supports a standard charge and discharge current of 50A, with maximum pulse discharge up to 150A, and operates within temperatures from -20°C to 60°C. With a cycle life of over 50,000 cycles and compatibility with popular inverters like Victron and SMA, it`s designed for efficient, long-lasting performance in parallel systems. Custom voltages upon request.',
				characters: [
					'100% Depth of Discharge (DoD)',
					'Safe — No risk of thermal runaway',
					'Long Lifespan/Cycle Life',
					'Wide operating temperature',
					'Low ESR',
					'Green/Environmentally Friendly',
					'Low maintenance',
				],
				capacities: ['5kWh (100Ah)', '10kWh (200Ah)', '20kWh (400Ah)'],
				img: Product_rack,
				specifications: { 
					table: [
						{ label: 'Rated Energy', value: '5250WH (@50A at 25C) Discharge\nfrom 58.8V to 42V with 50A' },
						{ label: 'Max Charge Voltage', value: '58.8V.DC' },
						{ label: 'Voltage', value: '≥48V' },
						{ label: 'Min Discharge Voltage', value: '42V' },
						{ label: 'Charge Current', value: 'Standard Charge: 50A\nMax Charge: 100A' },
						{ label: 'Discharge Current', value: 'Standard Discharge: 50A\nMax Discharge: 100A\nPulse Current: 150A' },
						{ label: 'Operating Temperature', value: 'Charging: -10°C ~ 55°C\nDischarging: -20°C ~ 60°C' },
						{ label: 'Impedance', value: 'Impedance ≤8mΩ (AC Impedance, 1000 Hz 48V@25±3°C)' },
						{ label: 'Max Qty of Module in Parallel Connection', value: '≤15 Sets' },
						{ label: 'Cycle Life', value: '≥50000 Cycles with 50A at 20°C' },
						{ label: 'Storage Temperature / Humidity', value: '-20°C ~ + 60°C / ≤70%' },
						{ label: 'Support for Inverter Models', value: 'Victron, Pylon, SMA' },
						{ label: 'Safety Test', value: 'Insulation Resistance ≥50MΩ' },
						{ label: 'Dimensions', value: 'Length: ≤650mm\nWidth: ≤432mm\nHeight: ≤160mm' },
						{ label: 'Weight', value: 'About 112 Lbs.' },
					], 
					img: Img_rack, 
					pdf: PDF_rack },
			},
			{
				id: 4, name: 'ESC Rack Mount', model: '72V', like: false, price: '$ 131.00', sku: 'ESC-RM-5.25/48',
				description: 'Each Hybrid Supercapacitor Battery module has a rated voltage range from 42V to 58.8V, with a capacity options ranging from 5250Wh to 21000Wh. It supports a standard charge and discharge current of 50A, with maximum pulse discharge up to 150A, and operates within temperatures from -20°C to 60°C. With a cycle life of over 50,000 cycles and compatibility with popular inverters like Victron and SMA, it`s designed for efficient, long-lasting performance in parallel systems. Custom voltages upon request.',
				characters: [
					'100% Depth of Discharge (DoD)',
					'Safe — No risk of thermal runaway',
					'Long Lifespan/Cycle Life',
					'Wide operating temperature',
					'Low ESR',
					'Green/Environmentally Friendly',
					'Low maintenance',
				],
				capacities: ['5kWh (100Ah)', '10kWh (200Ah)', '20kWh (400Ah)'],
				img: Product_rack,
				specifications: { 
					table: [
						{ label: 'Rated Energy', value: 'WH (@50A at 25C) Discharge\nfrom 88.8V to 72V with 50A' },
						{ label: 'Max Charge Voltage', value: '88.8V.DC' },
						{ label: 'Voltage', value: '≥72V' },
						{ label: 'Min Discharge Voltage', value: '72V' },
						{ label: 'Charge Current', value: 'Standard Charge: 50A\nMax Charge: 100A' },
						{ label: 'Discharge Current', value: 'Standard Discharge: 50A\nMax Discharge: 100A\nPulse Current: 150A' },
						{ label: 'Operating Temperature', value: 'Charging: -10°C ~ 55°C\nDischarging: -20°C ~ 60°C' },
						{ label: 'Impedance', value: 'Impedance ≤8mΩ (AC Impedance, 1000 Hz 48V@25±3°C)' },
						{ label: 'Max Qty of Module in Parallel Connection', value: '≤15 Sets' },
						{ label: 'Cycle Life', value: '≥50000 Cycles with 50A at 20°C' },
						{ label: 'Storage Temperature / Humidity', value: '-20°C ~ + 60°C / ≤70%' },
						{ label: 'Support for Inverter Models', value: 'Victron, Pylon, SMA' },
						{ label: 'Safety Test', value: 'Insulation Resistance ≥50MΩ' },
						{ label: 'Dimensions', value: 'Length: ≤650mm\nWidth: ≤432mm\nHeight: ≤160mm' },
						{ label: 'Weight', value: 'About 112 Lbs.' },
					], 
					img: Img_rack, 
					pdf: PDF_rack },
			},
		]
	},
	wall: {
		description: 'Our HSC Wall mount battery is available in 24V, 48V, and 72V with capacity options ranging from 5kWh-20kWh as well as custom options. This is the Ideal model for residential usage and backup power. It supports a standard charge and discharge current of 50A, with maximum pulse discharge up to 150A, and operates within temperatures from -20°C to 60°C. With a cycle life of over 50,000 cycles and compatibility with popular inverters like Victron and SMA, it`s designed for efficient, long-lasting performance in parallel systems.',
		items: [
			{
				id: 1, name: 'ESC Wall Mount', model: '24V', like: false, price: '$ 131.00', sku: 'ESC-RM-5.25/48',
				description: 'Each Hybrid Supercapacitor Battery module has a rated voltage range from 42V to 58.8V, with a capacity options ranging from 5250Wh to 21000Wh. It supports a standard charge and discharge current of 50A, with maximum pulse discharge up to 150A, and operates within temperatures from -20°C to 60°C. With a cycle life of over 50,000 cycles and compatibility with popular inverters like Victron and SMA, it`s designed for efficient, long-lasting performance in parallel systems. Custom voltages upon request.',
				characters: [
					'100% Depth of Discharge (DoD)',
					'Safe — No risk of thermal runaway',
					'Long Lifespan/Cycle Life',
					'Wide operating temperature',
					'Low ESR',
					'Green/Environmentally Friendly',
					'Low maintenance',
				],
				capacities: ['5kWh (100Ah)', '10kWh (200Ah)', '20kWh (400Ah)'],
				img: Product_wall,
				specifications: { 
					table: [
						{ label: 'Rated Energy', value: 'WH (@50A at 25C) Discharge\nfrom 29.4V to 24V with 50A' },
						{ label: 'Max Charge Voltage', value: '29.4V.DC' },
						{ label: 'Voltage', value: '≥24V' },
						{ label: 'Min Discharge Voltage', value: '24V' },
						{ label: 'Charge Current', value: 'Standard Charge: 50A\nMax Charge: 100A' },
						{ label: 'Discharge Current', value: 'Standard Discharge: 50A\nMax Discharge: 100A\nPulse Current: 150A' },
						{ label: 'Operating Temperature', value: 'Charging: -10°C ~ 55°C\nDischarging: -20°C ~ 60°C' },
						{ label: 'Impedance', value: 'Impedance ≤8mΩ (AC Impedance, 1000 Hz 48V@25±3°C)' },
						{ label: 'Max Qty of Module in Parallel Connection', value: '≤15 Sets' },
						{ label: 'Cycle Life', value: '≥50000 Cycles with 50A at 20°C' },
						{ label: 'Storage Temperature / Humidity', value: '-20°C ~ + 60°C / ≤70%' },
						{ label: 'Support for Inverter Models', value: 'Victron, Pylon, SMA' },
						{ label: 'Safety Test', value: 'Insulation Resistance ≥50MΩ' },
						{ label: 'Dimensions', value: 'Length: ≤960mm\nWidth: ≤840mm\nHeight: ≤82mm' },
						{ label: 'Weight', value: 'About 153 Lbs.' },
					], 
					img: Img_wall, 
					pdf: PDF_wall },
			},
			{
				id: 2, name: 'ESC Wall Mount', model: '36V', like: true, price: '$ 121.30', sku: 'ESC-RM-5.25/48',
				description: 'Each Hybrid Supercapacitor Battery module has a rated voltage range from 42V to 58.8V, with a capacity options ranging from 5250Wh to 21000Wh. It supports a standard charge and discharge current of 50A, with maximum pulse discharge up to 150A, and operates within temperatures from -20°C to 60°C. With a cycle life of over 50,000 cycles and compatibility with popular inverters like Victron and SMA, it`s designed for efficient, long-lasting performance in parallel systems. Custom voltages upon request.',
				characters: [
					'100% Depth of Discharge (DoD)',
					'Safe — No risk of thermal runaway',
					'Long Lifespan/Cycle Life',
					'Wide operating temperature',
					'Low ESR',
					'Green/Environmentally Friendly',
					'Low maintenance',
				],
				capacities: ['5kWh (100Ah)', '10kWh (200Ah)', '20kWh (400Ah)'],
				img: Product_wall,
				specifications: { 
					table: [
						{ label: 'Rated Energy', value: 'WH (@50A at 25C) Discharge\nfrom 44.1V to 36V with 50A' },
						{ label: 'Max Charge Voltage', value: '44.1.DC' },
						{ label: 'Voltage', value: '≥36V' },
						{ label: 'Min Discharge Voltage', value: '36V' },
						{ label: 'Charge Current', value: 'Standard Charge: 50A\nMax Charge: 100A' },
						{ label: 'Discharge Current', value: 'Standard Discharge: 50A\nMax Discharge: 100A\nPulse Current: 150A' },
						{ label: 'Operating Temperature', value: 'Charging: -10°C ~ 55°C\nDischarging: -20°C ~ 60°C' },
						{ label: 'Impedance', value: 'Impedance ≤8mΩ (AC Impedance, 1000 Hz 48V@25±3°C)' },
						{ label: 'Max Qty of Module in Parallel Connection', value: '≤15 Sets' },
						{ label: 'Cycle Life', value: '≥50000 Cycles with 50A at 20°C' },
						{ label: 'Storage Temperature / Humidity', value: '-20°C ~ + 60°C / ≤70%' },
						{ label: 'Support for Inverter Models', value: 'Victron, Pylon, SMA' },
						{ label: 'Safety Test', value: 'Insulation Resistance ≥50MΩ' },
						{ label: 'Dimensions', value: 'Length: ≤960mm\nWidth: ≤840mm\nHeight: ≤82mm' },
						{ label: 'Weight', value: 'About 153 Lbs.' },
					], 
					img: Img_wall, 
					pdf: PDF_wall },
			},
			{
				id: 3, name: 'ESC Wall Mount', model: '48V', like: false, price: '$ 94.58', sku: 'ESC-RM-5.25/48',
				description: 'Each Hybrid Supercapacitor Battery module has a rated voltage range from 42V to 58.8V, with a capacity options ranging from 5250Wh to 21000Wh. It supports a standard charge and discharge current of 50A, with maximum pulse discharge up to 150A, and operates within temperatures from -20°C to 60°C. With a cycle life of over 50,000 cycles and compatibility with popular inverters like Victron and SMA, it`s designed for efficient, long-lasting performance in parallel systems. Custom voltages upon request.',
				characters: [
					'100% Depth of Discharge (DoD)',
					'Safe — No risk of thermal runaway',
					'Long Lifespan/Cycle Life',
					'Wide operating temperature',
					'Low ESR',
					'Green/Environmentally Friendly',
					'Low maintenance',
				],
				capacities: ['5kWh (100Ah)', '10kWh (200Ah)', '20kWh (400Ah)'],
				img: Product_wall,
				specifications: { 
					table: [
						{ label: 'Rated Energy', value: '5250WH (@50A at 25C) Discharge\nfrom 58.8V to 42V with 50A' },
						{ label: 'Max Charge Voltage', value: '58.8V.DC' },
						{ label: 'Voltage', value: '≥48V' },
						{ label: 'Min Discharge Voltage', value: '42V' },
						{ label: 'Charge Current', value: 'Standard Charge: 50A\nMax Charge: 100A' },
						{ label: 'Discharge Current', value: 'Standard Discharge: 50A\nMax Discharge: 100A\nPulse Current: 150A' },
						{ label: 'Operating Temperature', value: 'Charging: -10°C ~ 55°C\nDischarging: -20°C ~ 60°C' },
						{ label: 'Impedance', value: 'Impedance ≤8mΩ (AC Impedance, 1000 Hz 48V@25±3°C)' },
						{ label: 'Max Qty of Module in Parallel Connection', value: '≤15 Sets' },
						{ label: 'Cycle Life', value: '≥50000 Cycles with 50A at 20°C' },
						{ label: 'Storage Temperature / Humidity', value: '-20°C ~ + 60°C / ≤70%' },
						{ label: 'Support for Inverter Models', value: 'Victron, Pylon, SMA' },
						{ label: 'Safety Test', value: 'Insulation Resistance ≥50MΩ' },
						{ label: 'Dimensions', value: 'Length: ≤960mm\nWidth: ≤840mm\nHeight: ≤82mm' },
						{ label: 'Weight', value: 'About 153 Lbs.' },
					], 
					img: Img_wall, 
					pdf: PDF_wall },
			},
			{
				id: 4, name: 'ESC Wall Mount', model: '72V', like: false, price: '$ 94.58', sku: 'ESC-RM-5.25/48',
				description: 'Each Hybrid Supercapacitor Battery module has a rated voltage range from 42V to 58.8V, with a capacity options ranging from 5250Wh to 21000Wh. It supports a standard charge and discharge current of 50A, with maximum pulse discharge up to 150A, and operates within temperatures from -20°C to 60°C. With a cycle life of over 50,000 cycles and compatibility with popular inverters like Victron and SMA, it`s designed for efficient, long-lasting performance in parallel systems. Custom voltages upon request.',
				characters: [
					'100% Depth of Discharge (DoD)',
					'Safe — No risk of thermal runaway',
					'Long Lifespan/Cycle Life',
					'Wide operating temperature',
					'Low ESR',
					'Green/Environmentally Friendly',
					'Low maintenance',
				],
				capacities: ['5kWh (100Ah)', '10kWh (200Ah)', '20kWh (400Ah)'],
				img: Product_wall,
				specifications: { 
					table: [
						{ label: 'Rated Energy', value: 'WH (@50A at 25C) Discharge\nfrom 88.8V to 72V with 50A' },
						{ label: 'Max Charge Voltage', value: '88.8V.DC' },
						{ label: 'Voltage', value: '≥72V' },
						{ label: 'Min Discharge Voltage', value: '72V' },
						{ label: 'Charge Current', value: 'Standard Charge: 50A\nMax Charge: 100A' },
						{ label: 'Discharge Current', value: 'Standard Discharge: 50A\nMax Discharge: 100A\nPulse Current: 150A' },
						{ label: 'Operating Temperature', value: 'Charging: -10°C ~ 55°C\nDischarging: -20°C ~ 60°C' },
						{ label: 'Impedance', value: 'Impedance ≤8mΩ (AC Impedance, 1000 Hz 48V@25±3°C)' },
						{ label: 'Max Qty of Module in Parallel Connection', value: '≤15 Sets' },
						{ label: 'Cycle Life', value: '≥50000 Cycles with 50A at 20°C' },
						{ label: 'Storage Temperature / Humidity', value: '-20°C ~ + 60°C / ≤70%' },
						{ label: 'Support for Inverter Models', value: 'Victron, Pylon, SMA' },
						{ label: 'Safety Test', value: 'Insulation Resistance ≥50MΩ' },
						{ label: 'Dimensions', value: 'Length: ≤960mm\nWidth: ≤840mm\nHeight: ≤82mm' },
						{ label: 'Weight', value: 'About 153 Lbs.' },
					], 
					img: Img_wall, 
					pdf: PDF_wall },
			},
		]
	},
	soon: {
		description: '',
		items: [
			{ id: 1, name: 'Forklift', model: '', like: false, price: '$ 131.00', sku: 'ESC-RM-5.25/48', description: 'Figma ipsum component variant main layer. Background clip pen fill underline. Bold selection auto fill follower arrow scrolling overflow bold overflow. Move group blur effect arrow clip. Layer prototype connection subtract invite hand.', img: Product_Soon_1 },
			{ id: 2, name: 'Golf Cart', model: '', like: true, price: '$ 121.30', sku: 'ESC-RM-5.25/48', description: 'Figma ipsum component variant main layer. Background clip pen fill underline. Bold selection auto fill follower arrow scrolling overflow bold overflow. Move group blur effect arrow clip. Layer prototype connection subtract invite hand.', img: Product_Soon_2 },
			{ id: 3, name: 'Boat', model: '', like: false, price: '$ 94.58', sku: 'ESC-RM-5.25/48', description: 'Figma ipsum component variant main layer. Background clip pen fill underline. Bold selection auto fill follower arrow scrolling overflow bold overflow. Move group blur effect arrow clip. Layer prototype connection subtract invite hand.', img: Product_Soon_3 },
			{ id: 4, name: 'Forklift', model: '', like: false, price: '$ 131.00', sku: 'ESC-RM-5.25/48', description: 'Figma ipsum component variant main layer. Background clip pen fill underline. Bold selection auto fill follower arrow scrolling overflow bold overflow. Move group blur effect arrow clip. Layer prototype connection subtract invite hand.', img: Product_Soon_4 },
			{ id: 5, name: 'Golf Cart', model: '', like: true, price: '$ 121.30', sku: 'ESC-RM-5.25/48', description: 'Figma ipsum component variant main layer. Background clip pen fill underline. Bold selection auto fill follower arrow scrolling overflow bold overflow. Move group blur effect arrow clip. Layer prototype connection subtract invite hand.', img: Product_Soon_5 },
			{ id: 6, name: 'Boat', model: '', like: false, price: '$ 94.58', sku: 'ESC-RM-5.25/48', description: 'Figma ipsum component variant main layer. Background clip pen fill underline. Bold selection auto fill follower arrow scrolling overflow bold overflow. Move group blur effect arrow clip. Layer prototype connection subtract invite hand.', img: Product_Soon_6 },
		]
	},
}